@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.boost {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 20px;
  height: 100vh;
}
@media (min-width: 1100px) {
  .boost_1 {
    position: relative;
    max-width: 823px;
    width: 100%;
  }
}
.boost_1 {
  position: relative;
  max-width: 823px;
  width: 100%;
  background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.up {
  position: absolute;
  right: 0;
  top: 0;
}
.bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}
.logo {
  padding-left: 110px;
  padding-right: 147px;
}
.boost_2 {
  color: var(--White, #fff);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.5%; /* 34.71px */
  padding-left: 30px;
  padding-left: 147px;
  padding-right: 147px;
}
.boost_3 {
  padding-top: 20px;
  /* max-width: 580px; */
  width: 100%;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.boost_4 {
  display: flex;
  border-bottom: 1px solid #dadada;
}
.boost_5 {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.boost_6 {
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.login {
  color: #dadada;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 126px;
  padding-bottom: 16px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}
/* .border-b {
  color: #333333 !important;
  border-bottom: 4px solid #333333;
  font-weight: 600 !important;
} */
.signup {
  color: #dadada;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 126px;
  padding-bottom: 16px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}
.boost_8 {
  color: #333;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 100px;
}

.boost_9 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
}
.boost_10 {
  /* max-width: 365px; */
  max-width: 500px;
  width: 100%;
  padding-right: 5px;
  margin: auto;
}
.boost_11 {
  margin-top: 63px;
}
.boost_12 {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.boost_13 {
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .boost_14 {
    /* max-width: 290px; */
    width: 100%;
  }
  .mt {
    display: none;
  }
}
.boost_14 {
  border-radius: 10px;
  border: 1px solid #333333;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  height: 55px;
  /* max-width: 290px; */
  width: 100%;
  padding-left: 50px;
  padding-right: 20px;
}
.message {
  position: absolute;
  left: 20px;
  top: 18px;
}

.forgot {
  margin-top: 8px;
  text-align: right;
  color: #52af6c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.loginbtn {
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  background: #1c1b21;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;
}
.loginbtn:hover {
  background: transparent;
  color: #333333;
  border: 1px solid #333333;
}

.hr {
  border: 1px solid #dadada;
  margin-top: 50px;
  margin-bottom: 40px;
}
.register {
  text-align: center;
  padding-bottom: 40px;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.now {
  color: #333333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
  cursor: pointer;
}
@media (max-width: 950px) {
  .boost {
    flex-wrap: wrap;
  }
  .boost_1 {
    height: 400px !important;
  }
  .boost_1 {
    position: relative;
    max-width: 950px !important;
    width: 100%;
    align-items: center !important;
  }
  .boost_1 {
    position: relative;
    /* max-width: 823px; */
    width: 100%;
  }
  .boost_1 {
    align-items: center;
  }
}
@media (max-width: 786px) {
  .mt {
    display: block !important;
  }
  .up {
    display: none;
  }
  .bottom {
    display: none;
  }
  .logo {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 5px;
  }
  .boost_2 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    text-align: center;
  }
  .boost_8 {
    font-size: 20px;
    margin-top: 35px;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
    width: 190px;
  }
  .boost {
    gap: 1px !important;
  }
  .boost_11 {
    margin-top: 34px;
  }
  .boost_12 {
    font-size: 12px;
  }
  .forgot {
    font-size: 12px;
  }
  .boost_14 {
    font-size: 10px;
    width: 100% !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .register {
    font-size: 12px;
  }
  .now {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media (max-width: 1350px) {
  /* .boost_1 {
    position: relative;
    max-width: 553px;
    width: 100%;
    background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
  .boost_3 {
    padding-top: 20px;
    max-width: 480px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  } */
  .logo {
    padding-left: 50px;
    padding-right: 50px;
  }
  /* .boost_2 {
    padding-left: 80px;
    padding-right: 80px;
  } */
}
@media (max-width: 1200px) {
  .boost_1 {
    position: relative;
    max-width: 553px;
    width: 100%;
    background: linear-gradient(180deg, #303030 0%, #202020 47.92%, #000 100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }
  .boost_3 {
    padding-top: 20px;
    max-width: 480px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo {
    padding-left: 80px;
    padding-right: 80px;
  }
  .boost_2 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 1050px) {
  .logo {
    padding-left: 23px;
    padding-right: 23px;
  }
  .boost_2 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-height: 900px) {
  .boost_8 {
    margin-top: 100px;
  }
  .boost_11 {
    margin-top: 63px !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 50px !important;
    margin-bottom: 40px !important;
  }
}
@media (max-height: 900px) {
  .boost_8 {
    margin-top: 30px;
  }
  .boost_11 {
    margin-top: 20px !important;
  }
  .hr {
    border: 1px solid #dadada;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }
}
@media (max-width: 1015px) {
  .logo {
    padding-left: 10px;
    padding-right: 10px;
    width: 300px !important;
    margin: auto;
  }
}
.fp {
  font-size: 14px !important;
  text-align: right;
  margin-top: 10px;
  cursor: pointer;
}
.r_me {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}
.r_me p {
  color: #6b7280;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.s-up {
  margin-top: 20px !important;
}
.s-up-form {
  margin-top: 10px !important;
}
.s-up-hr {
  border: none;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

@media (max-height: 800px) {
  .boost_14 {
    font-size: 12px;
    height: 37px;
  }
  .message {
    position: absolute;
    left: 20px;
    top: 5px;
  }
  .loginbtn {
    height: 50px;
    margin-top: 14px;
  }
  .register {
    padding-bottom: 10px !important;
    font-size: 14px !important;
  }
  .s-up-hr {
    border: none;
    margin-top: 0px !important;
    margin-bottom: 5px !important;
  }
}
