@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif !important;
}

.fabg {
  border-radius: 35px;
  background: #eafcfe;
  filter: blur(4px);
}
.cardsinnershadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.cardsinnershadow2 {
  box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

/* Toogle */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #27a5bc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #27a5bc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.tcyan {
  color: #36e0f7 !important;
}

.clickcolor {
  color: #ee1b50;
}
.card-s {
  box-shadow: 0px 0px 166px 17px rgba(0, 0, 0, 0.05);
}
.er {
  box-shadow: 0px 0px 114px 0px rgba(0, 0, 0, 0.1);
}
.faq {
  border-radius: 8px;
  border: 1px solid #ced2da;
  background: #fff;
  box-shadow: 0px 4px 166.7px 0px rgba(0, 0, 0, 0.06);
}
html {
  scroll-behavior: smooth;
}
.team_main {
  max-width: 1370px;
  width: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 56px;
  padding-bottom: 76px;
}

.team_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

@media (max-width: 1024px) {
  .team_1 {
    justify-content: center;
  }
}

.team_2 {
  width: 300px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team_2 h1 {
  margin-top: 20px;
  margin-bottom: 4px;
  color: #333;
  text-align: center;
  font-size: 18.438px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.4px;
}

.team_2 p {
  color: #55676b;
  text-align: center;
  font-size: 19.523px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}.world_1 {
  color: var(--Secondary, #000);
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  max-width: 920px;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}

.world_2 {
  color: var(--Secondary, #ee1b50);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
}

.ch {
  font-size: 35px;
}

.world_p {
  margin-top: 12px;
  margin-bottom: 80px;
  color: #72839a;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.world_3 {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.world_main {
  padding-top: 60px;
  padding-bottom: 100px;
  max-width: 1370px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}



.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: #ed1b50;
  border: none;
  border-radius: 60%;
  padding: 15px;
  cursor: pointer;
  display: none;
  transition: opacity 0.3s;
}

.scroll-to-top-button.visible {
  display: block;
}




.action-buttons {
  display: flex;
  gap: 10px;
}

.action-buttons button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}

.action-buttons button:hover {
  background-color: #0056b3;
}
.action-buttons button.delete-button {
  background-color: #dc3545;
  /* Red color */
}


.admincontainer {
  /* max-width: 800px; Adjust this value as needed */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.applications-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.applications-table th,
.applications-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.applications-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.applications-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.applications-table tr:hover {
  background-color: #e6e6e6;
}

.applications-table .action-buttons {
  text-align: center;
}

.applications-table .delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.applications-table .delete-button:hover {
  background-color: #d32f2f;
}



.applications-table .approve-button {
  background-color: hwb(91 20% 4%);
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.applications-table .approve-button:hover {
  background-color: hwb(91 20% 4%);
}





/* Add Hosts */

.add-host-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 50px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type='text'],
input[type='email'] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type='submit'] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #45a049;
}