.team_main {
    max-width: 1370px;
    width: 100%;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 56px;
    padding-bottom: 76px;
}
.articles-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 50px;
}
.article-title{
    font-size: 35px;
    color: #333;
    font-size: 35px;
    font-weight: 700;
    margin-top: 70px;
    text-align: center;

}
h1 {
    font-size: 35px;
    color: #333;
    font-weight: 700;
    margin-top: 80px;
}
h2 {
    font-size: 22px;
    color: #333;
    font-weight: 700;
    margin-top: 10px;
    text-align: justify;
}
.article-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.article-card:hover {
    transform: translateY(-5px);
}

.article-card h3 {
    margin-top: 0;
    font-size: 1.5rem;
}

.article-card p {
    margin: 10px 0;
}

.article-card img {
    width: 100%;
    border-radius: 8px;
}

.article-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.article-image {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.article-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.article-author {
    font-style: italic;
    margin-bottom: 1rem;
}

.article-content {
    font-size: 20px;
    line-height: 2;

}

p{
    text-align: justify;
}