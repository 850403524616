.content-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-group {
    margin-bottom: 20px;
}
.input-group2 {
    margin-bottom: 20px;
    border-color: grey;
    border-width: 2px;
    border-radius: 10px;
}

.field-label {
    font-weight: bold;
}

.input-field[type="text"],
.input-field[type="number"],
.textarea-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}

.btn-primary-style {
    background-color: #007bff;
    color: #fff;
}

.btn-primary-style:hover {
    background-color: #0056b3;
}
.articles-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.articles-table th,
.articles-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.articles-table th {
    background-color: #f2f2f2;
    vertical-align: middle;
    /* Align header content vertically */
}

.articles-table img {
    display: block;
    margin: 0 auto;
    vertical-align: middle;
    /* Align image within table cell */
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.action-buttons button {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
}

.action-buttons button:hover {
    background-color: #0056b3;
}
.action-buttons button.delete-button {
    background-color: #dc3545;
    /* Red color */
}